import React from 'react'
import BackgroundImage from 'gatsby-background-image'
import { TweenMax, TimelineMax, Sine } from 'gsap/TweenMax'
import placeholderImage from '../images/office.png'
import styles from '../styles/components/image-reveal.module.scss'

const mapNumber = (X, A, B, C, D) => ((X - A) * (D - C)) / (B - A) + C
// from http://www.quirksmode.org/js/events_properties.html#position
const getMousePos = e => {
  let posx = 0
  let posy = 0
  if (!e) e = window.event
  if (e.pageX || e.pageY) {
    posx = e.pageX
    posy = e.pageY
  } else if (e.clientX || e.clientY) {
    posx =
      e.clientX + document.body.scrollLeft + document.documentElement.scrollLeft
    posy =
      e.clientY + document.body.scrollTop + document.documentElement.scrollTop
  }
  return { x: posx, y: posy }
}

const ImageReveal = class extends React.Component {
  constructor(props) {
    super(props)
    // reference to the DOM node
    this.parentRef = React.createRef()
    this.maskRef = React.createRef()
    this.imageRef = React.createRef()
    // reference to the animation
    this.myTween = null
    this.tl = null
  }

  initEvents() {
    this.positionElement = ev => {
      const mousePos = getMousePos(ev)
      const docScrolls = {
        left: window.pageXOffset,
        top: window.pageYOffset,
      }
      if (this.maskRef !== null) {
        //this.maskRef.style.top = `${mousePos.y + 5 - docScrolls.top}px`
        this.maskRef.style.right = `${-0.08 * mousePos.x - docScrolls.left}px`
      }
    }
    if (this.parentRef !== null) {
      this.mouseenterFn = ev => {
        this.positionElement(ev)
        //this.showImage()
        this.growImage()
      }
      this.mousemoveFn = ev =>
        requestAnimationFrame(() => {
          this.positionElement(ev)
        })
      this.mouseleaveFn = () => {
        //this.hideImage()
        this.resetImage()
      }
    }
    this.parentRef.addEventListener('mouseenter', this.mouseenterFn)
    this.parentRef.addEventListener('mousemove', this.mousemoveFn)
    this.parentRef.addEventListener('mouseleave', this.mouseleaveFn)
  }

  growImage() {
    TweenMax.killTweensOf(this.maskRef)

    this.myTween = new TimelineMax({
      onStart: () => {
        this.parentRef.style.opacity = 1
      },
    })
      .add('begin')
      // make sure TweenMax.to maintain transformations while hovering (.from will return to default styling)
      .add(
        new TweenMax.to(this.maskRef, 0.4, {
          ease: 'power4.out',
          x: '-15px',
          y: '15px',
          scale: '1.25',
          opacity: '0.75',
        }),
        'begin'
      )
  }

  resetImage() {
    TweenMax.killTweensOf(this.maskRef)

    this.myTween = new TimelineMax().add(
      new TweenMax.to(this.maskRef, 0.25, {
        ease: 'power4.out',
        scale: '1',
      }),
      'begin'
    )
  }

  showImage() {
    TweenMax.killTweensOf(this.maskRef)
    TweenMax.killTweensOf(this.imageRef)

    this.myTween = new TimelineMax({
      onStart: () => {
        this.parentRef.style.opacity = 1
      },
    })
      .add('begin')
      .add(
        new TweenMax(this.maskRef, 0.25, {
          ease: 'power4.out',
          startAt: { x: '-100%' },
          x: '0%',
        }),
        'begin'
      )
      .add(
        new TweenMax(this.imageRef, 0.25, {
          ease: 'power4.out',
          startAt: { x: '100%' },
          x: '0%',
        }),
        'begin'
      )
  }
  hideImage() {
    TweenMax.killTweensOf(this.maskRef)
    TweenMax.killTweensOf(this.imageRef)

    this.myTween = new TimelineMax({
      onStart: () => {
        TweenMax.set(this.parentRef, { zIndex: 999 })
      },
      onComplete: () => {
        TweenMax.set(this.parentRef, { zIndex: '' })
        TweenMax.set(this.parentRef, { opacity: 0 })
      },
    })
      .add('begin')
      .add(
        new TweenMax(this.maskRef, 0.15, {
          ease: 'power4.out',
          x: '100%',
        }),
        'begin'
      )
      .add(
        new TweenMax(this.imageRef, 0.15, {
          ease: 'power4.out',
          x: '-100%',
        }),
        'begin'
      )
  }

  componentDidMount() {
    // use the node ref to create the animation
    this.initEvents()
  }
  render() {
    return (
      <>
        <div
          ref={div => (this.parentRef = div)}
          className={styles.hoverParent}
        ></div>
        <div ref={div => (this.maskRef = div)} className={styles.previewImage}>
          <BackgroundImage
            className={styles.imageContainer}
            ref={div => (this.imageRef = div)}
            fluid={this.props.image}
          >
            {/* <img src={placeholderImage} alt={this.props.title} /> */}
            <div className={styles.imageOverlay} />
          </BackgroundImage>
        </div>
      </>
    )
  }
}

export default ImageReveal
