import React from 'react'
import { Link, graphql } from 'gatsby'
import styles from '../styles/pages/lab.module.scss'
import classNames from 'classnames/bind'
import Reveal from 'react-reveal/Reveal'
import '../styles/animate.css'

import Layout from '../components/layout'
import SEO from '../components/SEO'
import EmailCollect from '../components/emailCollect'
import Footer from '../components/footer'
import BlogPostPreview from '../components/blogPostPreview'

import placeholderImage from '../images/office.png'
import ImageReveal from '../components/imageReveal'
//import '../components/animation/imageReveal'

let cx = classNames.bind(styles)

const LabPage = class extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      isMobile: true,
      activeCategory: 'All',
      activeHighlight: null,
      showAllPosts: false,
      defaultListLength: 4,
    }

    this.updateHighlight = this.updateHighlight.bind(this)
  }
  componentDidMount() {
    this.handleResize()
    // window.onresize = () => {
    //   if (window.innerWidth <= 768 !== this.state.isMobile) {
    //     this.setState(prevState => ({
    //       isMobile: window.innerWidth <= 768,
    //     }))
    //   }
    // }

    window.addEventListener('resize', this.handleResize)
    // window.onscroll = () => {
    //   let visiblePreviews = []
    //   this.previewRefs.forEach(ref => {
    //     if (ref.current) {
    //       let refTop = ref.current.getBoundingClientRect().top
    //       if (refTop < window.innerHeight) {
    //         visiblePreviews.push(ref)
    //         console.log(ref)
    //       }
    //     }
    //   })
    // }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize)
  }

  handleResize = () => {
    if (window.innerWidth <= 768 && !this.state.isMobile) {
      this.setState({ isMobile: true })
    } else if (window.innerWidth > 768 && this.state.isMobile) {
      this.setState({ isMobile: false })
    }
  }

  setFilter(category) {
    this.setState({
      activeCategory: category.target.innerText,
    })
  }

  updateHighlight(isActive, id) {
    if (isActive) this.setState(() => ({ activeHighlight: id }))
    else this.setState(() => ({ activeHighlight: null }))
  }

  toggleShowAllPosts() {
    this.setState({ showAllPosts: !this.state.showAllPosts })
  }

  render() {
    let categories = [
      'All',
      'Strategy',
      'Technology',
      'Design',
      'People',
      'Process',
      'Insights',
    ]
    let categoriesJSX = categories.map((category, i) => {
      let isActive = this.state.activeCategory === category
      let categoryClass = cx('category', {
        active: isActive,
      })
      return (
        <Reveal key={category} effect="fadeIn" duration={350}>
          <span
            className={categoryClass}
            key={i}
            onClick={this.setFilter.bind(this)}
            label={category}
          >
            {category}
          </span>
        </Reveal>
      )
    })
    const hideCategoriesFilter = this.props.data.allContentfulBlogPostList
      .edges[0].node.hideCategoriesFilter
    const blogPosts = this.props.data.allContentfulBlogPostList.edges[0].node
      .blogPosts

    let currListLength = 0 // keep track of filtered list length to determine whether to show 'See More' button

    // console.log(
    //   'blog post length:' +
    //     blogPosts.length +
    //     ' default list length: ' +
    //     this.state.defaultListLength
    // )
    return (
      <Layout>
        <SEO customTitle="Creative Lab" pagePath="creative-lab" />
        <div className={styles.container}>
          <Reveal effect="fadeInUpShorter" duration={550}>
            <div className={styles.pageTitle}>Creative Lab</div>
          </Reveal>
          {!hideCategoriesFilter && (
            <div className={styles.categories}>
              <div>{categoriesJSX}</div>
            </div>
          )}
          <div className={styles.postList}>
            {blogPosts.map((post, i) => {
              if (
                (post.categories &&
                  post.categories.includes(this.state.activeCategory)) ||
                this.state.activeCategory === 'All'
              )
                currListLength++
              //console.log('currListLength: ', currListLength)
              if (!this.state.showAllPosts && i >= this.state.defaultListLength)
                return
              else {
                return (
                  <BlogPostPreview
                    key={post.slug}
                    to={'/lab/' + post.slug}
                    id={i.toString()}
                    showCategories={!hideCategoriesFilter}
                    categories={post.categories}
                    activeCategory={this.state.activeCategory}
                    title={post.title}
                    update={this.updateHighlight}
                    highlight={this.state.activeHighlight}
                    isMobile={this.state.isMobile}
                    featured={true}
                    image={post.previewImage.fluid}
                  />
                )
              }
            })}
            {blogPosts.length > this.state.defaultListLength &&
              currListLength > this.state.defaultListLength && (
                <div
                  className={styles.seeMoreButton}
                  onClick={this.toggleShowAllPosts.bind(this)}
                >
                  <span>
                    {this.state.showAllPosts ? 'See Less' : 'See More'}
                  </span>
                </div>
              )}
          </div>
        </div>
        <EmailCollect />
        <Footer />
      </Layout>
    )
  }
}

export default LabPage

export const pageQuery = graphql`
  query {
    allContentfulBlogPostList {
      edges {
        node {
          hideCategoriesFilter
          blogPosts {
            title
            slug
            previewImage {
              fluid(maxWidth: 430, quality: 90) {
                ...GatsbyContentfulFluid_withWebp_noBase64
              }
            }
            categories
            featured
          }
        }
      }
    }
  }
`
