import React from 'react'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import styles from '../styles/components/blog-post-preview.module.scss'
import classNames from 'classnames/bind'
import '../styles/animate.css'

import ImageReveal from '../components/imageReveal'
//import '../components/animation/imageReveal'

let cx = classNames.bind(styles)

class BlogPostPreview extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      onScreen: false,
    }

    this.ref = React.createRef()
  }

  checkOnScreen = () => {
    if (
      this.ref.current.getBoundingClientRect().bottom - 50 <
      window.innerHeight
    ) {
      this.setState({
        onScreen: true,
      })
    }
  }

  componentDidMount() {
    window.addEventListener('scroll', this.checkOnScreen)
    this.checkOnScreen()
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.checkOnScreen)
  }

  render() {
    let highlight =
      this.props.highlight && this.props.highlight === this.props.id
    let fade = this.props.highlight ? !highlight : false
    if (
      this.props.activeCategory === 'All' ||
      (this.props.categories &&
        this.props.categories.includes(this.props.activeCategory))
    ) {
      return (
        <Link
          ref={this.ref}
          to={this.props.to}
          className={cx('postPreview', {
            highlight: highlight,
            fade: fade,
            featured: this.props.featured,
            onScreen: this.state.onScreen,
          })}
          onMouseEnter={() => this.props.update(true, this.props.id)}
          onMouseLeave={() => this.props.update(false)}
        >
          {!this.props.isMobile && this.props.featured && (
            <ImageReveal image={this.props.image} title={this.props.title} />
          )}
          {this.props.isMobile && this.props.featured && (
            // <Reveal effect="fadeInGrow" duration={750}>
            <div
              className={cx('previewImage', { highlight: highlight })}
              style={{ zIndex: 0 }}
            >
              <Img
                fluid={this.props.image}
                alt={this.props.title}
                style={{ height: '100%' }}
              />
              <div className={styles.imageOverlay} />
            </div>
            // </Reveal>
          )}
          {/* <Reveal effect="fadeInUpShorter" duration={1000}> */}
          <div className={styles.category}>
            {this.props.showCategories &&
              this.props.categories &&
              this.props.categories.map((category, i) => {
                return <span key={i}>{category} </span>
              })}
          </div>
          {/* </Reveal> */}
          {/* <Reveal effect="fadeGrowIn" duration={1000} fraction={1}> */}
          <div
            className={styles.postTitle}
            // onMouseEnter={() => this.props.update(true, this.props.id)}
            // onMouseLeave={() => this.props.update(false)}
          >
            {/* {highlight && (
            <div className={styles.previewImage}>
              <img src={placeholderImage} alt={this.props.title} />
              <div className={styles.imageOverlay} />
            </div>
            <ImageReveal title={this.props.title} />
          )} */}
            {/* {!this.props.isMobile && this.props.featured && (
              <ImageReveal image={this.props.image} title={this.props.title} />
            )} */}
            <span
              style={{ zIndex: 100 }}
              onMouseEnter={e => e.preventDefault()}
            >
              {this.props.title}{' '}
            </span>
          </div>
          {/* </Reveal> */}
        </Link>
      )
    } else return null
  }
}

export default BlogPostPreview
